<template>
  <div>
    <b-dropdown
      v-if="selectedInvoiceAccount"
      variant="outline-primary"
      toggle-class="d-flex align-items-center"
    >
      <template #button-content>
        <p class="font-weight-bolder mb-0">
          {{ selectedInvoiceAccount.name }}
        </p>
      </template>
      <b-dropdown-group
        id="dropdown-invoice-account-list"
        header="Mes comptes de facturation"
        class="disable-padding-right"
      >
        <b-dropdown-item
          v-for="invoiceAccount in invoiceAccounts"
          :key="invoiceAccount.ID"
          @click="selectInvoiceAccount(invoiceAccount)"
        >
          <feather-icon size="16" icon="ApertureIcon" class="mr-1" />
          {{ invoiceAccount.name }}
        </b-dropdown-item>
      </b-dropdown-group>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdownGroup,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BDropdownGroup,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  computed: {
    selectedInvoiceAccount: {
      get() {
        return this.$store.state.invoiceAccounts.selectedInvoiceAccount;
      },
    },
    invoiceAccounts: {
      get() {
        return this.$store.state.invoiceAccounts.invoiceAccounts;
      },
    },
  },
  methods: {
    selectInvoiceAccount(invoiceAccount) {
      this.$store.commit(
        "invoiceAccounts/setSelectedInvoiceAccount",
        invoiceAccount
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.user-flex-column {
  flex-direction: column !important;
}

.dropdown-user-position {
  padding: 0rem !important;
}

.align-items-center {
  align-content: flex-end;
}

.z-index-cover {
  z-index: 1000;
}

ul {
  padding-right: 0px !important;
}
</style>
