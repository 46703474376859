<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userProfile.first_name }}
        </p>
        <span class="user-status font-weight-bolder">{{
          userProfile.last_name
        }}</span>
      </div>
      <b-avatar
        size="40"
        :src="resolveAvatarLink(userProfile.first_name, userProfile.last_name)"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      :to="{ name: 'user-profile', params: { id: userProfile.ID } }"
      link-class="d-flex align-items-center user-item-dropdown"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profil</span>
    </b-dropdown-item>

    <b-dropdown-divider
      v-if="
        userProfile.role !== 'BUSINESS_CUSTOMER' &&
        userProfile.role !== 'BUSINESS_BO'
      "
    />

    <b-dropdown-item
      v-if="
        userProfile.role !== 'BUSINESS_CUSTOMER' &&
        userProfile.role !== 'BUSINESS_BO'
      "
      :to="{ name: 'user-manage' }"
      link-class="d-flex align-items-center user-item-dropdown"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Gestion</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Se déconnecter</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <small class="ml-1 my-50">v{{ appVersion }}</small>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { resolveAvatarLink } from "@core/utils/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    userProfile: {
      get() {
        if (!this.$store.state.auth.userProfile) {
          this.logout();
        }
        return this.$store.state.auth.userProfile;
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout", this.$ability).then(() => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Vous avez été déconnecté",
              icon: "CoffeeIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
      });
    },
  },
  setup() {
    return {
      resolveAvatarLink,
    };
  },
};
</script>
