<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-row class="nav-left-button align-center">
      <b-col>
        <invoice-accounts-dropdown />
      </b-col>
    </b-row>

    <!-- Left Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BRow, BCol, BLink, BNavbarNav } from "bootstrap-vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
import InvoiceAccountsDropdown from "@core/layouts/components/app-navbar/components/InvoiceAccountsDropdown.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BNavbarNav,

    // Navbar Components
    UserDropdown,
    InvoiceAccountsDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.nav-left-button {
  @media (max-width: (map-get($grid-breakpoints, xl))) {
    margin-left: 0.5rem;
  }
}
</style>
