export default [
  // CLIENT SECTION
  {
    header: "Aperçu",
    action: "read",
  },
  {
    title: "Accueil",
    route: "board-public",
    icon: "HomeIcon",
    action: "read",
    resource: "PublicBoard",
  },
  {
    header: "Outils",
    action: "read",
  },
  {
    title: "Décision Immo",
    route: "decimmo-searcher",
    action: "read",
    resource: "DecimmoPages",
    icon: "AwardIcon",
  },
  {
    header: "Intégration",
    action: "read",
  },
  {
    title: "Portail d'annonces",
    route: "realestate-searcher",
    action: "read",
    resource: "RealestatePages",
    icon: "BookOpenIcon",
  },
  {
    title: "Estimateur de bien",
    route: "estimate-demo",
    action: "read",
    resource: "EstimatePages",
    icon: "DollarSignIcon",
  },
  {
    header: "APIs",
    action: "read",
  },
  {
    title: "Annonces",
    action: "read",
    resource: "RealestatePages",
    icon: "CodepenIcon",
    children: [
      {
        title: "Gestion",
        route: {
          name: "realestate-manage",
        },
        action: "read",
        resource: "RealestatePages",
        icon: "KeyIcon",
      },
      {
        title: "API Documentation",
        route: {
          name: "realestate-redoc",
          query: { jsonName: "0.1.0_code_realestate_v2.json" },
        },
        action: "read",
        resource: "RealestatePages",
        icon: "FileTextIcon",
      },
      {
        title: "Présentation",
        href: "https://www.cadredevie.fr/marche-immo",
        action: "read",
        resource: "RealestatePages",
        icon: "NavigationIcon",
      },
    ],
  },
  {
    title: "Estimation",
    icon: "TrendingUpIcon",
    children: [
      {
        title: "Gestion",
        route: {
          name: "estimate-manage",
        },
        action: "read",
        resource: "EstimatePages",
        icon: "KeyIcon",
      },
      {
        title: "API Documentation",
        route: {
          name: "estimate-redoc",
          query: { jsonName: "1.0.0_code_estimate_v1.json" },
        },
        action: "read",
        resource: "EstimatePages",
        icon: "FileTextIcon",
      },
      {
        title: "Présentation",
        href: "https://www.cadredevie.fr/estimation",
        action: "read",
        resource: "EstimatePages",
        icon: "NavigationIcon",
      },
    ],
  },
  {
    title: "Emplacement",
    icon: "CheckCircleIcon",
    children: [
      {
        title: "Gestion",
        route: {
          name: "situation-manage",
        },
        action: "read",
        resource: "SituationPages",
        icon: "KeyIcon",
      },
      {
        title: "API Documentation",
        route: {
          name: "situation-redoc",
          query: { jsonName: "1.0.0_code_situation_v1.json" },
        },
        action: "read",
        resource: "SituationPages",
        icon: "FileTextIcon",
      },
      {
        title: "Présentation",
        href: "https://www.cadredevie.fr/emplacement",
        action: "read",
        resource: "SituationPages",
        icon: "NavigationIcon",
      },
    ],
  },
  // ADMIN SECTION
  {
    header: "Admin",
    action: "read",
    resource: "adminLinks",
  },
  {
    title: "Datas",
    icon: "ActivityIcon",
    children: [
      {
        title: "Ventes",
        route: {
          name: "ads-sales",
        },
        action: "read",
        resource: "AdsPages",
        icon: "KeyIcon",
      },
      {
        title: "Locations",
        route: {
          name: "ads-rentals",
        },
        action: "read",
        resource: "AdsPages",
        icon: "GridIcon",
      },
      {
        title: "Programmes",
        route: {
          name: "ads-programs",
        },
        action: "read",
        resource: "AdsPages",
        icon: "AwardIcon",
      },
    ],
  },
  {
    title: "Flux",
    route: "flow-list",
    icon: "WindIcon",
    action: "read",
    resource: "FlowPages",
  },
  {
    title: "Produits",
    route: "product-list",
    icon: "PackageIcon",
    action: "read",
    resource: "ProductPages",
  },
  {
    title: "Clients",
    route: "customer-list",
    icon: "StarIcon",
    action: "read",
    resource: "CustomerPages",
  },
  {
    title: "Utilisateurs",
    route: "user-list",
    icon: "UserPlusIcon",
    action: "read",
    resource: "UserPages",
  },
];
